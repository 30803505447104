* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.depenedentNameInput .form-control::placeholder {
  color: #212529 !important;
  opacity: 1;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.offcanvas-header {
  background-color: #005aab;
  color: #fff;
  text-transform: uppercase;
}

.menu-list li {
  padding: 10px;
  transition: 0.3s ease;
}

.menu-list li:hover {
  background-color: #dc3545;
  color: #fff;
}

.menu-list a {
  color: #005aab;
  transition: 0.3s ease;
  font-size: 20px;
  text-decoration: none;
}

.logo img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  height: 100px;
  object-fit: contain;
  margin-bottom: 1rem;
}

.main-wrap {
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 3rem;
  box-shadow: 0px 1px 15px rgb(25 25 25 / 10%);
  -webkit-box-shadow: 0px 1px 15px rgb(25 25 25 / 10%);
  border-radius: 10px;
  background-color: #fff;
}

.wrap-title {
  color: #005aab;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.vertical-wrap {
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0px 1px 15px rgb(25 25 25 / 10%);
  -webkit-box-shadow: 0px 1px 15px rgb(25 25 25 / 10%);
  border-radius: 10px;
  background-color: #005aab;
  color: #fff;
  transition: 0.3s ease;
  margin-bottom: 1rem;
}

.vertical-wrap:hover {
  background-color: #ee1b24;
}

.brand-btn {
  padding: 1rem;
  border-radius: 3px;
  background-color: #005aab;
  transition: 0.3s ease;
  color: #fff;
  border: none;
  cursor: pointer;
}

.brand-btn-danger {
  padding: 1rem;
  border-radius: 3px;
  background-color: #dc3545;
  transition: 0.3s ease;
  color: #fff;
  border: none;
}

.brand-btn:hover {
  background-color: #ee1b24;
  color: #fff;
}

.brand-table thead {
  background-color: #005aab;
  color: #fff;
}

.brand-label {
  color: #005aab;
  margin-bottom: 8px;
}

.required-mark {
  color: #ee1b24;
  margin-left: 2px;
}

.error {
  color: #ee1b24;
}

.details-tab-link.active {
  background-color: #005aab !important;
  border-radius: 3px !important;
}

.details-tab-link {
  color: #dc3545;
}

.tab-grid {
  padding: 1rem;
  border: 1px solid #005aab;
  text-align: center;
  border-radius: 3px;
  margin-bottom: 1rem;
}

.tab-grid h5 {
  color: #005aab;
}

.coverage-badge span {
  padding: 10px;
  margin: 3px;
  font-size: 14px;
}

.btncontainer {
  display: flex;
  justify-content: flex-end;
}

.p-row {
  display: flex;
  flex-direction: row;
}
/* RESPONSIVE CSS STARTS */

@media (max-width: 767px) {
  .main-wrap {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
  }
  h2.wrap-title {
    text-align: center;
  }

  .res-cust-btn {
    margin-top: 2rem;
  }
  .steps-div div,
  .steps-div a {
    font-size: 12px !important;
  }
}

/* RESPONSIVE CSS ENDS */

.icofont-navigation-menu:before {
  content: "\efa2";
}

/* PAGINATION CSS STARTS */

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  color: #007bff;
}

.pagination > .active > a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: blue;
}

.pagination > li > a {
  border: 1px solid #cccccc;
  padding: 10px 18px;
  outline: none;
  cursor: pointer;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #cccccc;
  border-color: #007bff;
  outline: none;
  color: white;
}

.pagination > li > a,
.pagination > li > span {
  color: #007bff;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.autocomplete-dropdown-container {
  position: absolute;
  /*border: 1px solid #bbbbbb;*/
  width: 40%;
  padding: 5px;
  /*background: white;*/
}
.suggestion-item {
  border: 1px solid #bbbbbb;
  padding: 5px;
  background: white;
}
.suggestion-item--active {
  border: 1px solid #bebebe;
  padding: 5px;
  background: #79e9f8;
}
.resent__email {
  width: 40%;
}

/* DASHBOARD PAGE CSS STARTS */

.content-panel {
  padding: 1rem;
}

.user-intro-msg h3 {
  text-align: center !important;
  color: #1d1d1d;
  margin-bottom: 2rem;
}

.stats-card {
  padding: 0.5rem;
  text-align: center;
  -webkit-box-shadow: 0 10px 15px rgb(25 25 25 / 10%);
  box-shadow: 0 10px 15px rgb(25 25 25 / 10%);
  border-radius: 5px;
  border: 1px solid #999999;
  margin-bottom: 1rem;
}

.stats-card h6 {
  color: #999999;
  text-align: center;
  text-transform: uppercase;
}

.stats-card h3 {
  color: #1d1d1d;
  font-weight: 400;
}

.card-link {
  transition: 0.3s ease;
}

.card-link:hover {
  transform: scale(1.1);
}

.card-link-disabled {
  cursor: not-allowed;
}

.disabled-card {
  background-color: #cbcbcb;
}

.disabled-card h6 {
  color: #999999 !important;
}

.prod-img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 1rem;
  width: 100%;
  height: 80px;
  object-fit: contain;
}
.prod-img-color {
  filter: brightness(0) saturate(100%) invert(83%) sepia(71%) saturate(648%)
    hue-rotate(317deg) brightness(112%) contrast(105%);
}

.prod-img-preview {
  filter: brightness(0) saturate(100%) invert(52%) sepia(58%) saturate(2480%)
    hue-rotate(115deg) brightness(94%) contrast(102%);
}

.prod-brand-text {
  text-align: center;
  color: #777777;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  font-size: 15px;
}

/* .overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
} */

/* DASHBOARD PAGE CSS ENDS */
/* MY HEALTHCARE PLAN CSS STARTS */

.filter-top-bar,
.plan-wrap {
  padding: 1rem;
  border-radius: 0.5rem 0 0 0.5rem;
  box-shadow: 0px 1px 15px rgb(25 25 25 / 10%);
  -webkit-box-shadow: 0px 1px 15px rgb(25 25 25 / 10%);
  background-color: #ffffff;
}

.filter-top-bar1 {
  top: 0;
}

.major-spacing {
  margin-top: 11rem;
}

.main-label {
  color: #1360ef;
}

.main-plan-title {
  font-weight: 600;
}

.plan-border-bottom {
  padding-bottom: 1rem;
  border-bottom: 2px solid #efefef;
  margin-bottom: 1rem;
}

.plan-badge {
  border: 1px solid #046791;
  border-radius: 2px;
  color: #046791;
  padding: 2px;
  font-size: 12px;
}

.plan-sub-label {
  font-size: 10px;
  font-weight: 600;
  color: #046791;
  margin-bottom: 0;
}

.plan-sub-data {
  font-size: 12px;
  margin-bottom: 0.5rem;
}

.plan-price-wrap {
  padding: 1rem;
  background-color: #046791;
  border-radius: 0 0.5rem 0.5rem 0;
  color: #ffffff;
}

.plan-price-wrap p {
  margin-bottom: 0;
}

.plan-price-wrap h3 {
  font-weight: 700;
  font-size: 2rem;
  margin: 0.5rem 0;
}

.flex-dir-column {
  flex-direction: column;
}

.resp-m-end {
  padding-right: 0 !important;
}

.resp-m-start {
  padding-left: 0 !important;
}

.checked {
  color: orange;
}

.button-body-for-loader {
  display: inline-flex;
}
.button-body-for-loader > span {
  padding: 3px;
  margin-right: 5px;
}

.activity-status-table thead tr th{
  background-color: #ffdb58;
}

@media (max-width: 991px) {
  .resp-m-end {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }

  .resp-m-start {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }

  .plan-wrap {
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0;
  }

  .plan-price-wrap {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.5rem;
  }

  .main-plan-badges {
    margin-bottom: 5px;
  }
}

.brand-card-title {
  padding: 0.6rem;
  background-color: #ffdb58;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  font-weight: 600;
}
.brand-card-title h5 {
  font-weight: 600;
}

/* MY HEALTHCARE PLAN CSS ENDS */


@media (max-width: 1000px) {
  .custom-date-input::-webkit-calendar-picker-indicator {
    display: none;
  }
}

/* Quote Form CSS Starts*/
.health-section {
  background-color: #046791;
  min-height: 100vh;
  place-content: center;
  display: grid;
  background-image: url(../public/assets/images/hero-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.health-form {
  padding: 1rem;
  background-color: #ffdb58;
  border-radius: 10px;
}

.health-form {
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 10px;
}
.health-content h1 {
  color: #ffffff;
  font-size: 4rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 300;
}
.brand-btn-quote:hover {
  background-color: #1360ef;
  color: #ffffff;
}

.brand-btn-quote {
  background-color: #046791;
  border: none;
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  transition: 0.3s ease;
}
/* Quote Form CSS Ends*/

/* HERO CSS STARTS */

.hero-section {
  background-color: #1e2dde;
  min-height: 100vh;
  place-content: center;
  display: grid;
  background-image: url(../public/assets/images/hero-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-form {
  padding: 1rem;
  background-color: #ffdb58;
  border-radius: 10px;
}

.hero-form h4 {
  color: #1e2dde;
  font-size: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.hero-form label {
  font-size: 15px;
}

.hero-content h1 {
  color: #ffdb58;
  font-size: 4rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 300;
}

.hero-content p {
  color: #ffffff;
  text-align: justify;
}

.hero-custom-shape-divider-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}
.w-20 {
  width: 20%;
}

.hero-custom-shape-divider-bottom svg {
  position: relative;
  display: block;
  width: calc(130% + 1.3px);
  height: 150px;
}

.hero-custom-shape-divider-bottom .shape-fill {
  fill: #ffffff;
}

/* HERO CSS ENDS */

/* RESPONSIVE CSS STARTS */

@media (max-width: 991px) {
  .hero-form {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .hero-section {
    height: auto;
    padding-bottom: 0;
  }

  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-custom-shape-divider-bottom {
    position: relative;
  }
}

/* COMMON CSS STARTS */

* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

.section-spacing {
  padding: 50px 0;
}
.panel-title h4 {
  color: #1e2dde !important;
  font-weight: 600;
}

.brand-input {
  background-color: #f3f3f3;
  border: none;
}

.brand-btn {
  background-color: #1e2dde;
  border: none;
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  transition: 0.3s ease;
}

.brand-btn-login {
  background-color: #1e2dde;
  border: none;
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  transition: 0.3s ease;
}

.brand-btn:hover {
  background-color: #1360ef;
  width: 100%;
}

.brand-link {
  color: #1e2dde;
  transition: 0.3s ease;
}

.brand-link:hover {
  color: #1360ef;
}

.brand-label {
  color: #005aab;
  margin-bottom: 8px;
}

/* COMMON CSS ENDS */


.swal2-container {
  z-index: 9999999;
}

.canvasjs-chart-credit
{
    display: none !important;
}