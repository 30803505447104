/* COMMON CSS STARTS */

* {
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none;
}

.panel-title h4 {
    color: #1d1d00;
}

.data-box {
    padding: 1rem;
    border: 1px solid #efefef;
    border-radius: 5px;
}

i.success {
    color: #4BB543;
}

i.failed {
    color: #ff0000;
}

i.waiting {
    color: #ffc107;
}

.brand-tab {
    color: #1d1d1d;
}

    .brand-tab:hover {
        color: #fcce8a;
    }

    .brand-tab.active, .brand-tab:hover, .brand-tab:focus {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 3px solid #fcce8a !important;
    }

a.yellow-highlight {
    color: #fcce8a !important;
    transition: 0.3s ease;
}

    a.yellow-highlight:hover {
        color: #0a58ca !important;
    }

.required-mark {
    color: #ff0000;
}

/* COMMON CSS ENDS */

/* SIDEBAR CSS STARTS */

.bg-sidebar {
    background-color: #f4f4f4;
}

.sidebar-logo img {
    width: 100%;
    height: 45px;
    object-fit: contain;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.sidebar-item a {
    color: #1d1d1d;
}

    .sidebar-item a:hover {
        color: #0a58ca;
    }

.sidebar-active-item a {
    color: #0a58ca;
}

/* SIDEBAR CSS ENDS */

/* SWITCHER BUTTON CSS STARTS */

.switcher {
    justify-content: end;
    display: grid;
    margin-bottom: 1rem;
}

.btn-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.btn-color-mode-switch {
    display: inline-block;
    margin: 0px;
    position: relative;
}

    .btn-color-mode-switch > label.btn-color-mode-switch-inner {
        margin: 0px;
        width: 140px;
        height: 40px;
        background-color: #fcce8a;
        border-radius: 26px;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease;
        /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
        display: block;
    }

        .btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
            content: attr(data-on);
            position: absolute;
            font-size: 20px;
            font-weight: 600;
            top: 7px;
            right: 20px;
            color: #222;
        }

        .btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
            content: attr(data-off);
            width: 70px;
            height: 36px;
            background: #fff;
            border-radius: 26px;
            position: absolute;
            font-size: 20px;
            display: flex;
            justify-content: center;
            left: 2px;
            top: 2px;
            text-align: center;
            transition: all 0.3s ease;
            box-shadow: 0px 0px 6px -2px #111;
            padding: 5px 0px;
            color: #222;
            font-weight: 600;
        }

    .btn-color-mode-switch input[type="checkbox"] {
        cursor: pointer;
        width: 50px;
        height: 25px;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 1;
        margin: 0px;
    }

        .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner {
            background-color: #fcce8a;
        }

            .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after {
                content: attr(data-on);
                left: 68px;
            }

            .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before {
                content: attr(data-off);
                right: auto;
                left: 20px;
            }


/* SWITCHER BUTTON CSS ENDS */

/* DASHBOARD PAGE CSS STARTS */

.content-panel {
    padding: 1rem;
}

.user-intro-ms {
    text-align: center;
    justify-content: center !important;
}

.user-intro-msg h3 {
    text-align: center !important;
    color: #1d1d1d;
    margin-bottom: 2rem;
}

.stats-card {
    padding: 0.5rem;
    text-align: center;
    -webkit-box-shadow: 0 10px 15px rgb(25 25 25 / 10%);
    box-shadow: 0 10px 15px rgb(25 25 25 / 10%);
    border-radius: 5px;
    border: 1px solid #999999;
}

    .stats-card h6 {
        color: #999999;
        text-align: center;
        text-transform: uppercase;
    }

    .stats-card h3 {
        color: #1d1d1d;
        font-weight: 400;
    }

    .stats-card:hover {
        background: #fff1bc;
    }

/* DASHBOARD PAGE CSS ENDS */

/* ACTIVITY PAGE CSS STARTS */

.filter-box-title {
    background-color: #efefef;
    padding: 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

    .filter-box-title h5 {
        margin-bottom: 0;
        font-size: 18px;
    }

.filter-box-content {
    border: 1px solid #efefef;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 1rem;
}

.status-mark {
    display: flex;
    align-items: center;
}

    .status-mark i {
        font-size: 10px;
        margin-right: 12px;
    }

    .status-mark h6 {
        margin-bottom: 0;
    }

.cust-info h6 {
    margin-bottom: 0;
}

.cust-info p, .last-received-date-time p {
    margin-bottom: 0;
    font-size: 14px;
}

.cust-width {
    width: 30%;
}

.activity-cust-data {
    margin-bottom: 2rem;
}

.brand-offcanvas-header {
    background-color: #FFDB58;
    color: #1E2DDE;
}

#customerDetail {
    /*z-index: 9999;*/
    width: 40%;
    padding: 0px;
}

#agent {
    width: 40%;
    padding: 0px;
}

.offcanvas-backdrop {
    z-index: 1 !important;
}

#editHealthcare {
    z-index: 2;
    width: 60%;
    padding: 0px;
}

.pagination {
    display: flex;
    justify-content: end;
}

.w-30 {
    width: 30% !important;
}

.w-70 {
    width: 70% !important;
}

li > div {
    width: 100%;
}

/* ACTIVITY PAGE CSS ENDS */
/* ACCOUNT-DETAILS PAGE CSS - YOUR PRODUCTS STARTS */

.product-card {
    padding: 10px;
    border: 1px solid #efefef;
    margin-bottom: 1rem;
}

.product-card-content {
    padding: 1rem;
    border: 1px solid #efefef;
}

    .product-card-content i {
        color: #a17533;
        font-size: 2rem;
    }

.prod-content {
    margin-top: 0.85rem;
}

.dashboard-product > td button {
    min-height: 20px !important;
    line-height: 6px !important;
    font-size: 14px
}


.button-body-for-loader {
    display: inline-flex;
}

    .button-body-for-loader > span {
        padding: 3px;
        margin-right: 5px;
    }

.iFrame-dashboard > td {
    word-break: break-all;
}

.brand-card-title {
    padding: 0.6rem;
    background-color: #FFDB58;
    border-radius: 5px;
    margin-bottom: 1rem;
}

    .brand-card-title h5, h4 {
        color: #1E2DDE;
        margin-bottom: 0;
    }

.brand-link h5, h4, h6 {
    color: #1E2DDE;
    margin-bottom: 0;
}

.brand-link {
    padding: 10px;
}

.brand-btn {
    background-color: #1E2DDE;
    border: none;
    padding: 0.5rem;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    border-radius: 5px;
    transition: 0.3s ease;
}

.bg-sidebar {
    background-color: #1E2DDE;
}

.sidebar-content-alignment {
    min-height: 100%;
    position: fixed;
}

.bg-sidebar {
    background-color: #1E2DDE;
}

.sidebar-item a {
    color: #ffffff;
}

a#dropdownUser1 {
    color: #FFDB58 !important;
}

.sidebar-item a:hover {
    color: #FFDB58;
}

.sidebar-active-item a {
}

.nav-link:focus {
    color: #FFDB58 !important;
}


.sidebar-logo img {
    width: 100%;
    height: 60px;
    object-fit: contain;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.filter-box-title {
    background-color: #FFDB58;
    padding: 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.activity-status-table thead tr th{
    background-color: #FFDB58;
    color: #1E2DDE;
}

.brand-card {
    padding: 5px;
    border: none;
    -webkit-box-shadow: 0 10px 15px rgb(25 25 25 / 10%);
    box-shadow: 0 10px 15px rgb(25 25 25 / 10%);
    border-radius: 5px;
}

/* ACCOUNT-DETAILS PAGE CSS - YOUR PRODUCTS STARTS */

.header-of-welcome {
    background: #1e2dde;
    height: 250px;
}
.show-dependent-list td {
    text-align: center
}

#dropdownUser1{
    max-width: 195px;
}
#dropdownUser1 >span {
   white-space: normal;
}

.input-group-text {
    padding: 0.375rem !important;
    border-radius: unset !important;

}

.date-range{
    display: flex;
    align-items: baseline;
}