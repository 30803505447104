.image-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.upload-button {
    display: inline-block;
    background-color: #3498db;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.upload-button:hover {
    background-color: #2980b9;
}

.selected-image {
    margin-top: 20px;
    max-width: 100%;
    height: auto;
}
