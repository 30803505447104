.resent_email {
  width: 40%;
}
.verified__email_image {
  width: 500px;
  height: 500px;
}

.email__header {
  font-size: 18px;
}
